import apiConfig from "../config/_config";
import Cookies from 'js-cookie';
export async function loginUser(username, password) {
    const loginApiUrl = `${apiConfig}/authentication/login`;
    const userInfoApiUrl =`${apiConfig}/user/get`;
    const loginData = {
        username: username,
        password: password,
        
    };

    try {
        const loginResponse = await fetch(loginApiUrl, {
            method: 'POST',
            mode : 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(loginData),
        });
        console.log(loginResponse)
        if (!loginResponse.ok) {
            throw new Error('Login request failed with status ' + loginResponse.status);
        }
        else{
            
        const loginResult = await loginResponse.json();
        console.log(loginResult);
        Cookies.set('Token',loginResult.message.idToken, {
            secure: true});      

        const idToken = loginResult.message.idToken; 
        const userInfoResponse = await fetch(userInfoApiUrl, {
            method: 'GET',
            headers: {
                'Authorization': idToken,
            },
        });


        const userInfo = await userInfoResponse.json();
        
        console.log(  userInfo);
Cookies.set('Username', userInfo.message.name);
      return {"userInfo" : userInfo , 'idToken' : idToken};
    }
    } catch (error) {
        console.log(error);

        throw new Error(error);
        
    }
}
