
import apiConfig from "../config/_config";
const apiUrl = `${apiConfig}/model/list`;

export async function getModelList(token) {
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': token,
            },
        });
    
        if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching model list:", error);
        throw error;
    }
    }