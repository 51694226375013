// config.status.mjs
import process from "process";

export const statusInstanceMappings = {
  "processing": process.env.STATUS_PROCESSING_INSTANCE || "bg-warning",
  "uploading": process.env.STATUS_UPLOADING_INSTANCE || "bg-info",
  "completed": process.env.STATUS_COMPLETED_INSTANCE || "bg-success",
  "error": process.env.STATUS_ERROR_INSTANCE || "bg-danger",
  "uploaded": process.env.STATUS_UPLOADED_INSTANCE || "bg-primary",
  "confirmed": process.env.STATUS_CONFIRMED_INSTANCE || "bg-success",
};

export  const statusDocMappings = {
  "processing": process.env.STATUS_PROCESSING_DOC || "bg-warning",
  "uploading": process.env.STATUS_UPLOADING_DOC || "bg-info",
  "completed": process.env.STATUS_COMPLETED_DOC || "bg-success",
  "error": process.env.STATUS_ERROR_DOC || "bg-danger",
  "uploaded": process.env.STATUS_UPLOADED_DOC || "bg-primary",
  "confirmed": process.env.STATUS_CONFIRMED_DOC || "bg-success",
};
