import apiConfig from "../config/_config"; 

export default async function docRelance(Oldstatus, CurrentStatus, NewStatus, InstanceId,token) {
  const apiUrl = `${apiConfig}/admin/document/statusupdate`;
  const requestBody = JSON.stringify({ 

    "OldStatus":Oldstatus, 

    "CurrentStatus": CurrentStatus, 

    "NewStatus":  NewStatus, 

    "InstanceId":  InstanceId,  

    "ListOnly" : "true" 

} );

  let shouldRetry = true;
  let docList = [];

  while (shouldRetry) {
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: requestBody
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la requête');
      }

      const responseData = await response.json();
      console.log(responseData);
      docList = responseData.items;
      shouldRetry = responseData.updateItems !== 0 && docList.length !== 0; 
    } catch (error) {
      console.error("Erreur lors de l'appel de l'API:", error);
      shouldRetry = false; 
    }
  }

  return docList;
}

