import React, { useEffect, useState } from "react";
import fetchClient from "../../../../API/ClientList";
import fetchModel from "../../../../API/ModelADM";
import { useNavigate } from "react-router-dom";

const ClientSection = ({ token }) => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null); // Added state for selectedModel
  const navigate = useNavigate(); // Get the navigate function from useNavigate

  const fetchClientsForModel = async (clientId) => {
    try {
      const modelsData = await fetchModel(clientId, token);
      setModels(modelsData);
      if (modelsData.length > 0) {
        // Optionally set a default model here
      }
    } catch (error) {
      console.error("Error fetching models for client:", error);
    }
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientsData = await fetchClient(token, null);
        setClients(clientsData);
        if (clientsData.length > 0) {
          const defaultClient = clientsData[0];
          setSelectedClient(defaultClient.ClientId);
          fetchClientsForModel(defaultClient.ClientId);
        }
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchClients();
  }, [token]);

  const handleRedirect = () => {
    if (selectedClient) {
      localStorage.setItem('selectedClient', selectedClient);
      localStorage.setItem('selectedModel', selectedModel);
      navigate('/Admin/Dashboard');
    } else {
      console.warn('Veuillez sélectionner un client et un modèle avant de rediriger.');
    }
  };

  return (
    <div>
      <div className="form-group">
        <label htmlFor="selectClient">Select a client:</label>
        <select
          className="form-control"
          id="selectClient"
          onChange={(e) => {
            const selectedValue = e.target.value;
            setSelectedClient(selectedValue);
            fetchClientsForModel(selectedValue);
          }}
        >
          <option>Choose a client</option>
          {clients.map((client) => (
            <option key={client.ClientId} value={client.ClientId}>
              {client.ClientId}
            </option>
          ))}
        </select>
      </div>

      {selectedClient && (
        <div className="form-group">
          <label htmlFor="selectSecondInput">Second input dependent on the client:</label>
          <select
            className="form-control"
            id="selectSecondInput"
            onChange={(e) => setSelectedModel(e.target.value)} // Update selectedModel
          >
            <option value="">Choose a model</option>
            <option value="none">Aucun</option> {/* Ajout de l'option "Aucun" */}
            {models.map((model) => (
              <option key={model.ModelId} value={model.ModelId}>
                {model.ModelName}
              </option>
            ))}
          </select>
        </div>
      )}

      <button type="button" className="btn btn-primary" onClick={handleRedirect}>
        Rediriger
      </button>
    </div>
  );
};

export default ClientSection;
