/** @format */

import { useEffect, useState, useContext } from "react";
import { getInstance } from "../../../API/InstancesADM";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  faArrowLeft,
  faArrowRight,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import "./tableau.css";
import fetchModel from "../../../API/ModelADM";
import NavBar from "../NavBar/NavBar";
import { FiltersContext } from "../../../Context/main";
import { useTranslation } from "react-i18next";
import * as configStatus from "../../../config/config.status";
import process from "process";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import InstancePopup from "./popInstances";
/*import PieChart from "../Stats/StatsStatusInstances";
import StatsTable from "./statsTableau";*/
const InstancesTableau = () => {
  const [instances, setInstances] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [modelList, setModelList] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedModel, setSelectedModel] = useState("All");
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedInstance, setSelectedInstance] = useState("");
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [sortByByName, setSortByByName] = useState(true);
  const [sortByById, setSortByById] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const { filters, setFilters } = useContext(FiltersContext);
  const [sortByDateAscending, setSortByDateAscending] = useState(false);
  const [sortByNameAscending, setSortByNameAscending] = useState(false);
  const [sortByStatusAscending, setSortByStatusAscending] = useState(false);
  const navigate = useNavigate();
  const instancesPerPage = 10;
  const userRole = localStorage.getItem("role");
  const showInstances =
    process.env.REACT_APP_DASHBOARD_INSTANCES === "true" ||
    userRole === process.env.REACT_APP_ROLE_ADMIN;
  const showDocsSearch =
    process.env.REACT_APP_DASHBOARD_DOCS === "true" ||
    userRole === process.env.REACT_APP_ROLE_ADMIN;
  const showFilters =
    process.env.REACT_APP_DASHBOARD_FILTERS === "true" ||
    userRole === process.env.REACT_APP_ROLE_ADMIN;
  const idToken = Cookies.get("Token");
  const client = localStorage.getItem("selectedClient");
  const model = localStorage.getItem("selectedModel");
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getInstance(client, model, idToken);
        let instances = response.message.list.map((instance) => ({
          ...instance,
          LastUpdate: formatDate(instance.LastUpdate),
        }));

        // Filtre pour ne garder que les instances avec des documents
        instances = instances.filter((instance) => instance.CreatedDocs > 0);

        const statuses = [
          ...new Set(instances.map((instance) => instance.Status)),
        ];
        setUniqueStatuses(statuses);

        setInstances(instances);
        setIsLoading(false);
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la récupération des données de l'API",
          error
        );
        setIsLoading(false);
      }
    }

    fetchData();

    if (idToken) {
      fetchModel(client, idToken)
        .then((data) => {
          setModelList(data);
        })
        .catch((error) => {
          console.error("Error fetching model list:", error);
        });
    }
  }, [idToken]);

  const applyFilters = (data) => {
    const filteredData = data.filter((instance) => {
      const modelMatch =
        selectedModel === "All" ||
        modelList.find((model) => model.ModelId === instance.ModelId)
          ?.ModelName === selectedModel;

      const dateMatch =
        !selectedDate || instance.LastUpdate.includes(selectedDate);

      const statusMatch =
        selectedStatus === "All" || instance.Status === selectedStatus;

      const nameA = instance.InstanceName
        ? instance.InstanceName.toLowerCase()
        : "";
      const nameB = filterValue ? filterValue.toLowerCase() : "";

      const idA = instance.InstanceId ? instance.InstanceId.toLowerCase() : "";
      const idB = filterValue ? filterValue.toLowerCase() : "";

      let nameFilterMatch = false;
      let idFilterMatch = false;

      if (sortByByName) {
        nameFilterMatch = nameA.includes(nameB);
      } else if (sortByById) {
        idFilterMatch = idA.startsWith(idB) || idA === idB;
      }

      return (
        (modelMatch || !selectedModel) &&
        (dateMatch || !selectedDate) &&
        (statusMatch || selectedStatus === "All") &&
        ((sortByByName && nameFilterMatch) ||
          (sortByById && idFilterMatch) ||
          (!filterValue && !sortByByName && !sortByById))
      );
    });

    return filteredData;
  };
  const paginateInstances = (data, pageNumber) => {
    const startIndex = (pageNumber - 1) * instancesPerPage;
    const endIndex = startIndex + instancesPerPage;
    return data.slice(startIndex, endIndex);
  };

  const sortedData = applyFilters(instances).sort((a, b) => {
    let comparisonResult = 0;

    if (sortByStatusAscending) {
      const statusComparison = a.Status.localeCompare(b.Status);
      if (statusComparison !== 0) {
        comparisonResult = statusComparison;
      }
    }

    if (comparisonResult === 0) {
      if (sortByDateAscending) {
        // Tri par date et heure croissantes
        const dateA = new Date(
          parseInt(a.LastUpdate.substr(6, 4)),
          parseInt(a.LastUpdate.substr(3, 2)) - 1,
          parseInt(a.LastUpdate.substr(0, 2)),
          parseInt(a.LastUpdate.substr(11, 2)), // Heure
          parseInt(a.LastUpdate.substr(14, 2)) // Minutes
        );
        const dateB = new Date(
          parseInt(b.LastUpdate.substr(6, 4)),
          parseInt(b.LastUpdate.substr(3, 2)) - 1,
          parseInt(b.LastUpdate.substr(0, 2)),
          parseInt(b.LastUpdate.substr(11, 2)), // Heure
          parseInt(b.LastUpdate.substr(14, 2)) // Minutes
        );

        if (dateA.getTime() === dateB.getTime()) {
          // Si les dates sont égales, on compare les heures
          comparisonResult =
            dateA.getHours() - dateB.getHours() ||
            dateA.getMinutes() - dateB.getMinutes();
        } else {
          comparisonResult = dateA - dateB;
        }
      } else {
        // Tri par date et heure décroissantes
        const dateA = new Date(
          parseInt(a.LastUpdate.substr(6, 4)),
          parseInt(a.LastUpdate.substr(3, 2)) - 1,
          parseInt(a.LastUpdate.substr(0, 2)),
          parseInt(a.LastUpdate.substr(11, 2)), // Heure
          parseInt(a.LastUpdate.substr(14, 2)) // Minutes
        );
        const dateB = new Date(
          parseInt(b.LastUpdate.substr(6, 4)),
          parseInt(b.LastUpdate.substr(3, 2)) - 1,
          parseInt(b.LastUpdate.substr(0, 2)),
          parseInt(b.LastUpdate.substr(11, 2)), // Heure
          parseInt(b.LastUpdate.substr(14, 2)) // Minutes
        );

        if (dateA.getTime() === dateB.getTime()) {
          // Si les dates sont égales, on compare les heures
          comparisonResult =
            dateB.getHours() - dateA.getHours() ||
            dateB.getMinutes() - dateA.getMinutes();
        } else {
          comparisonResult = dateB - dateA;
        }
      }

      if (comparisonResult === 0 && !sortByNameAscending && !sortByById) {
        const nameA = a.InstanceName ? a.InstanceName.toLowerCase() : "";
        const nameB = b.InstanceName ? b.InstanceName.toLowerCase() : "";

        comparisonResult = nameA.localeCompare(nameB);
      }
    }

    return comparisonResult;
  });
  const paginatedData = paginateInstances(sortedData, currentPage);

  const paginate = (pageNumber) => {
    const nextPageIndex = pageNumber - 1;
    const startIndex = nextPageIndex * instancesPerPage;
    const endIndex = startIndex + instancesPerPage;
    const nextPageData = instances.slice(startIndex, endIndex);

    if (nextPageData.length > 0) {
      setCurrentPage(pageNumber);
    }
  };

  const handleModelChange = (e) => {
    const selectedModel = e.target.value;
    setSelectedModel(selectedModel);
    setFilters({ ...filters, selectedModel });
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setSelectedDate(selectedDate);
    setFilters({ ...filters, selectedDate });
  };

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setSelectedStatus(selectedStatus);
    setFilters({ ...filters, selectedStatus });
  };

  const toggleSortByDate = () => {
    setSortByDateAscending(!sortByDateAscending);
  };

  const getStatusClass = (status) => {
    const statusMappings = configStatus.statusInstanceMappings;

    if (statusMappings.hasOwnProperty(status)) {
      return statusMappings[status];
    } else {
      return "bg-info";
    }
  };

  const resetFilters = () => {
    setSelectedDate("");
    setFilterValue("");
    setSortByByName(false);
    setSortByById(false);
    setSelectedStatus("All");
  };

  const openPopup = (instance) => {
    setSelectedInstance(instance);
    setPopupVisible(true);
  };
  const closePopup = () => {
    setSelectedInstance(null);
    setPopupVisible(false);
  };

  const formatDate = (dateStr) => {
    const year = dateStr.substr(0, 4);
    const month = dateStr.substr(4, 2);
    const day = dateStr.substr(6, 2);
    const hour = dateStr.substr(9, 2);
    const minute = dateStr.substr(11, 2);
    const second = dateStr.substr(13, 2);

    return `${day}/${month}/${year} ${hour}:${minute}`;
  };
  const handleInputChange = (e) => {
    setFilterValue(e.target.value);
  };

  /*  const fetchAndGenerateCSVData = async (instanceId) => {
    try {
      const response = await fetchDocuments(instanceId, idToken);
      const csvData = await response.json();

      // Formater la date pour chaque document
      const formattedCSVData = csvData.map((doc) => ({
        ...doc,
        LastUpdate: formatDate(doc.LastUpdate),
      }));

      // Générer les données CSV à partir des documents formatés
      const generateCSVDataInstance = (documents) => {
        const data = [];
        data.push(["Date", "Nom du document", "Statut"]);

        documents.forEach((document) => {
          data.push([document.LastUpdate, document.FileName, document.Status]);
        });

        return data; // Assurez-vous que les données sont retournées sous forme de tableau d'arrays
      };

      const csvInstanceData = generateCSVDataInstance(formattedCSVData);

      return csvInstanceData;
    } catch (error) {
      console.error(
        "Erreur lors de la récupération et génération des données CSV",
        error
      );
      return []; // En cas d'erreur, renvoyer une liste vide
    }
  }
const handleDownloadCSV = async (instanceId, idToken, instanceName) => {
  try {
    const response = await fetchAndGenerateCSVData(instanceId, idToken);
    const csvData = response;

    if (csvData.length > 0) {
      // Créer un lien de téléchargement programmatically
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `${instanceName}.csv`);
      link.click();
    } else {
      console.error('Aucune donnée CSV disponible.');
    }
  } catch (error) {
    console.error('Erreur lors de la récupération des données CSV :', error);
  }
};*/
  return (
    <>
      <NavBar />
      <div className='row'>
        <div className='col-md-6'>
          <div className='header'>
            <h2 style={{ textAlign: "left" }}>
              {t("Welcome")} {Cookies.get("Username")}
            </h2>
            <br />
            <h3 style={{ textAlign: "left" }}>{t("operations")}</h3>
            {
              //            <StatsTable instances={sortedData} />
            }{" "}
            <br />
          </div>
        </div>
        <div className='col-md-6'>
          <div className='chart-container'>
            {
              //            <PieChart instances={sortedData} />
            }
          </div>
          <h5 style={{ textAlign: "right" }}>Status repartition</h5>
        </div>

        <div></div>
      </div>
      <div className='content'>
        <div className='chart-container'>
          {/*<p>{t("text-operations-P1")}</p>a
          <p>{t("text-operations-P2")}</p>*/}
        </div>
      </div>

      {showInstances && (
        <div className='table-container' style={{ minHeight: "400px" }}>
          {isLoading ? (
            <table>
              <tbody>
                <tr>
                  <td colSpan='7'>{t("chargement")}</td>
                </tr>
              </tbody>
            </table>
          ) : paginatedData.length === 0 ? (
            <table className='table table-hover'>
              <thead>
                <tr>
                  <td style={{ width: "15%" }}>
                    <span
                      onClick={toggleSortByDate}
                      style={{ cursor: "pointer" }}
                    >
                      {t("dateCreationEntete")}
                      {sortByDateAscending ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "5px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "5px" }}
                        />
                      )}
                    </span>
                  </td>
                  <td style={{ width: "450px" }}>
                    {t("nomInstanceEntete")}
                    <button
                      // onClick={toggleSortByName}
                      style={{
                        marginLeft: "5px",
                        padding: "0",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      {/*sortByNameAscending ? (
                              <FontAwesomeIcon icon={faSortUp} />
                            ) : (
                              <FontAwesomeIcon icon={faSortDown} />
                            )*/}
                    </button>
                  </td>
                  <td title={t("Attendu")} style={{ width: "70px" }}>
                    EX
                  </td>
                  <td title={t("Uploadé")} style={{ width: "50px" }}>
                    UP
                  </td>
                  <td title={t("Crée")} style={{ width: "70px" }}>
                    CR
                  </td>
                  <td title={t("Completed")} style={{ width: "50px" }}>
                    CO
                  </td>

                  <td style={{ paddingRight: "40px" }}>
                    {t("statusEntete")}
                    <button
                      //onClick={toggleSortByStatus}
                      style={{
                        marginLeft: "5px",
                        padding: "0",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      {/*sortByStatusAscending ? (
                              <FontAwesomeIcon icon={faSortUp} />
                            ) : (
                              <FontAwesomeIcon icon={faSortDown} />
                            )*/}
                    </button>
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <td className='header-cell'>
                    <div className='flex-container'>
                      {showFilters && (
                        <input
                          style={{
                            boxSizing: "border-box",
                            width: "120px",
                            height: "30px",
                            borderRadius: "3px",
                            margin: "0",
                            border: "1px solid #ffff",
                            borderColor: selectedDate ? "#0034CF" : "#161616", // Couleur de bordure plus prononcée quand l'input a une valeur
                            color: "#3c6382",
                            paddingRight: selectedDate ? "30px" : "10px",
                            boxShadow: selectedDate ? "0 0 5px 0 #0034CF" : "", // Ombre plus intense si l'input a une valeur
                            transition: "border-color 0.3s, box-shadow 0.3s",
                            // Ajoute d'autres styles personnalisés ici si nécessaire
                          }}
                          type='text'
                          placeholder={t("JJMMAAAA")}
                          value={selectedDate}
                          onChange={handleDateChange}
                        />
                      )}
                    </div>
                  </td>
                  <td className='header-cell'>
                    {showFilters && (
                      <div
                        className='-containerflex'
                        style={{ display: "flex", alignItems: "left" }}
                      >
                        <div className='fixed-width'>
                          <div style={{ position: "relative", width: "100%" }}>
                            <input
                              id='filterInput'
                              style={{
                                marginLeft: "20px",
                                width: "auto",
                                height: "30px",
                                borderRadius: "3px",
                                margin: "0",
                                border: "1px solid #ffff",
                                borderColor: filterValue
                                  ? "#0034CF"
                                  : "#161616", // Couleur de bordure plus prononcée quand l'input a une valeur
                                color: "#3c6382",
                                paddingRight: filterValue ? "30px" : "10px",
                                boxShadow: filterValue
                                  ? "0 0 5px 0 #0034CF"
                                  : "", // Ombre plus intense si l'input a une valeur
                                transition:
                                  "border-color 0.3s, box-shadow 0.3s",
                              }}
                              type='text'
                              placeholder={t("filtreInstance-Recherche")}
                              value={filterValue}
                              onChange={handleInputChange}
                            />
                            <select
                              style={{
                                width: "auto", // 6px pour compenser la largeur des bordures
                                height: "30px",
                                borderRadius: "3px",
                                borderColor: "#3c6382",
                                color: "#3c6382",
                                boxSizing: "border-box",
                                // Ajoute d'autres styles personnalisés si nécessaire
                              }}
                              value={
                                sortByByName ? "name" : sortByById ? "id" : ""
                              }
                              onChange={(e) => {
                                if (e.target.value === "name") {
                                  setSortByByName(true);
                                  setSortByById(false);
                                } else if (e.target.value === "id") {
                                  setSortByByName(false);
                                  setSortByById(true);
                                } else {
                                  setSortByByName(false);
                                  setSortByById(false);
                                }
                              }}
                            >
                              <option value='name'>
                                {t("filtreInstance-Nom")}
                              </option>
                              <option value='id'>
                                {t("filtreInstance-Id")}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                  </td>

                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>

                  <td className='header-cell'>
                    {showFilters && (
                      <div className='flex-container'>
                        <div className='fixed-width'>
                          <select
                            style={{
                              marginLeft: "130px",
                              width: "auto",
                              height: "30px",
                              borderRadius: "3px",
                              borderColor: "#3c6382",
                              color: "#3c6382",
                            }}
                            value={selectedStatus}
                            onChange={handleStatusChange}
                          >
                            <option value='All'>{t("toutStatus")}</option>
                            {uniqueStatuses.map((status) => (
                              <option key={status} value={status}>
                                {status}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                  </td>
                  <td>
                    <button className='btn btn-danger' onClick={resetFilters}>
                      Delete Filters
                    </button>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan='7'>{t("noData")}</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table className='table table-hover'>
              <thead>
                <tr>
                  <td style={{ width: "15%" }}>
                    <span
                      onClick={toggleSortByDate}
                      style={{ cursor: "pointer" }}
                    >
                      {t("dateCreationEntete")}
                      {sortByDateAscending ? (
                        <FontAwesomeIcon
                          icon={faSortUp}
                          style={{ marginLeft: "5px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSortDown}
                          style={{ marginLeft: "5px" }}
                        />
                      )}
                    </span>
                  </td>
                  <td style={{ width: "450px" }}>
                    {t("nomInstanceEntete")}
                    <button
                      // onClick={toggleSortByName}
                      style={{
                        marginLeft: "5px",
                        padding: "0",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      {/*sortByNameAscending ? (
                              <FontAwesomeIcon icon={faSortUp} />
                            ) : (
                              <FontAwesomeIcon icon={faSortDown} />
                            )*/}
                    </button>
                  </td>
                  <td title={t("Attendu")} style={{ width: "70px" }}>
                    EX
                  </td>
                  <td title={t("Uploadé")} style={{ width: "50px" }}>
                    UP
                  </td>
                  <td title={t("Crée")} style={{ width: "70px" }}>
                    CR
                  </td>
                  <td title={t("Completed")} style={{ width: "50px" }}>
                    CO
                  </td>
                  <td style={{ maxWidth: "40px", paddingLeft: "60px" }}>
                    {t("modeleEntete")}
                  </td>
                  <td style={{ paddingRight: "40px" }}>
                    {t("statusEntete")}
                    <button
                      //onClick={toggleSortByStatus}
                      style={{
                        marginLeft: "5px",
                        padding: "0",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      {/*sortByStatusAscending ? (
                              <FontAwesomeIcon icon={faSortUp} />
                            ) : (
                              <FontAwesomeIcon icon={faSortDown} />
                            )*/}
                    </button>
                  </td>
                  <td>Client</td>
                  <td></td>
                </tr>

                <tr>
                  <td className='header-cell'>
                    <div
                      className='flex-container'
                      style={{ display: "flex", alignItems: "left" }}
                    >
                      {showFilters && (
                        <input
                          style={{
                            boxSizing: "border-box",
                            width: "auto",
                            height: "30px",
                            borderRadius: "3px",
                            border: "1px solid #ffff",
                            borderColor: selectedDate ? "#0034CF" : "#161616", // Couleur de bordure plus prononcée quand l'input a une valeur
                            color: "#3c6382",
                            paddingRight: selectedDate ? "30px" : "10px",
                            boxShadow: selectedDate ? "0 0 5px 0 #0034CF" : "", // Ombre plus intense si l'input a une valeur
                            transition: "border-color 0.3s, box-shadow 0.3s",
                            // Ajoute d'autres styles personnalisés ici si nécessaire
                          }}
                          type='text'
                          placeholder={t("JJMMAAAA")}
                          value={selectedDate}
                          onChange={handleDateChange}
                        />
                      )}
                    </div>
                  </td>
                  <td className='header-cell'>
                    {showFilters && (
                      <div
                        className='flex-container'
                        style={{ display: "flex", alignItems: "left" }}
                      >
                        <div className='fixed-width'>
                          <div style={{ position: "relative", width: "100%" }}>
                            <input
                              id='filterInput'
                              style={{
                                width: "auto",
                                height: "30px",
                                borderRadius: "3px",
                                border: "1px solid #ffff",
                                borderColor: filterValue
                                  ? "#0034CF"
                                  : "#161616", // Couleur de bordure plus prononcée quand l'input a une valeur
                                color: "#3c6382",
                                paddingRight: filterValue ? "30px" : "10px",
                                boxShadow: filterValue
                                  ? "0 0 5px 0 #0034CF"
                                  : "", // Ombre plus intense si l'input a une valeur
                                transition:
                                  "border-color 0.3s, box-shadow 0.3s",
                              }}
                              type='text'
                              placeholder={t("filtreInstance-Recherche")}
                              value={filterValue}
                              onChange={handleInputChange}
                            />
                            <select
                              style={{
                                width: "auto", // 6px pour compenser la largeur des bordures
                                height: "30px",
                                borderRadius: "3px",
                                borderColor: "#3c6382",
                                color: "#3c6382",
                                boxSizing: "border-box",
                                // Ajoute d'autres styles personnalisés si nécessaire
                              }}
                              value={
                                sortByByName ? "name" : sortByById ? "id" : ""
                              }
                              onChange={(e) => {
                                if (e.target.value === "name") {
                                  setSortByByName(true);
                                  setSortByById(false);
                                } else if (e.target.value === "id") {
                                  setSortByByName(false);
                                  setSortByById(true);
                                } else {
                                  setSortByByName(false);
                                  setSortByById(false);
                                }
                              }}
                            >
                              <option value='name'>
                                {t("filtreInstance-Nom")}
                              </option>
                              <option value='id'>
                                {t("filtreInstance-Id")}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                  </td>

                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='header-cell'>
                    {showFilters && (
                      <div className='fixed-width'>
                        <select
                          style={{
                            width: "200px",
                            height: "30px",
                            borderRadius: "3px",
                            borderColor: "#3c6382",
                            color: "#3c6382",
                          }}
                          value={selectedModel}
                          onChange={handleModelChange}
                        >
                          <option value='All'>{t("toutModele")}</option>
                          {modelList.map((model) => (
                            <option key={model.ModelId} value={model.ModelName}>
                              {model.ModelName}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </td>
                  <td className='header-cell'>
                    {showFilters && (
                      <div className='flex-container'>
                        <div className='fixed-width'>
                          <select
                            style={{
                              width: "auto",
                              height: "30px",
                              borderRadius: "3px",
                              borderColor: "#3c6382",
                              color: "#3c6382",
                            }}
                            value={selectedStatus}
                            onChange={handleStatusChange}
                          >
                            <option value='All'>{t("toutStatus")}</option>
                            {uniqueStatuses.map((status) => (
                              <option key={status} value={status}>
                                {status}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <button className='btn btn-danger' onClick={resetFilters}>
                      Delete Filters
                    </button>
                  </td>
                </tr>
              </thead>
              {paginatedData.map((instance, index) => (
                <tbody>
                  <tr key={instance.InstanceId}>
                    <td style={{ width: "15%" }}>{instance.LastUpdate}</td>

                    <td style={{ width: "450px", textAlign: "center" }}>
                      {instance.InstanceName ? (
                        <div
                          style={{
                            fontSize: "12px",
                            overflowX:
                              instance.InstanceName.length > 20
                                ? "auto"
                                : "visible",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {instance.InstanceName}
                        </div>
                      ) : (
                        "..."
                      )}
                    </td>
                    <td style={{ width: "80px" }}>{instance.ExpectedDocs}</td>
                    <td
                      style={{
                        width: "50px",
                        whiteSpace: "nowrap", // Empêche le texte de se découper sur plusieurs lignes
                        textAlign: "center",
                        color:
                          instance.UploadedDocs < instance.ExpectedDocs
                            ? "red"
                            : "inherit",
                      }}
                    >
                      {instance.UploadedDocs}
                    </td>
                    <td style={{ width: "70px" }}>{instance.CreatedDocs}</td>
                    <td
                      style={{
                        width: "50px",
                        whiteSpace: "nowrap", // Empêche le texte de se découper sur plusieurs lignes
                        textAlign: "center",
                        color:
                          instance.CompletedDocs >= instance.ExpectedDocs
                            ? "green"
                            : "inherit",
                      }}
                    >
                      {instance.CompletedDocs}
                    </td>
                    <td style={{ maxWidth: "40px", paddingLeft: "40px" }}>
                      {modelList.find(
                        (model) => model.ModelId === instance.ModelId
                      )?.ModelName || instance.ModelId}
                    </td>
                    <td
                      style={{ width: "40px", color: "#fff" }}
                      className={getStatusClass(instance.Status)}
                    >
                      {instance.Status}
                    </td>
                    <td>{instance.ClientId}</td>
                    <td></td>
                    {showDocsSearch && (
                      <td style={{ width: "75px" }}>
                        <FontAwesomeIcon
                          icon={faSearch}
                          onClick={() => {
                            localStorage.setItem(
                              "selectedInstance",
                              instance.InstanceId
                            );
                            localStorage.setItem(
                              "selectedClient",
                              instance.ClientId
                            );
                            localStorage.setItem(
                              "selectedInstanceName",
                              instance.InstanceName
                            );
                            navigate("/Admin/DashboardDocs");
                          }}
                          style={{
                            cursor:
                              instance.CreatedDocs === 0
                                ? "not-allowed"
                                : "pointer",
                            fontSize: "16px",
                            marginRight: "auto",
                            color:
                              instance.CreatedDocs === 0 ? "gray" : "inherit",
                          }}
                        />
                      </td>
                    )}
                    <td>
                      <button onClick={() => openPopup(instance)}>
                        Relancer
                      </button>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          )}
          <div
            className='pagination'
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              className='btn btn-danger'
              onClick={() => {
                navigate("/Admin/");
                localStorage.removeItem("selectedModel");
                localStorage.removeItem("selectedClient");
              }}
            >
              retour
            </button>
            {currentPage !== 1 && (
              <button
                className='custom-button'
                style={{
                  marginRight: "10px",
                  color: "#fff",
                }}
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{
                    cursor: "pointer",
                    fontSize: "16px",
                    marginLeft: "auto",
                  }}
                />
                {"  "}
                {t("btnPrecedent")}
              </button>
            )}
            {"   "}
            {instances.slice(
              currentPage * instancesPerPage,
              (currentPage + 1) * instancesPerPage
            ).length > 0 && (
              <button
                className='custom-button'
                onClick={() => paginate(currentPage + 1)}
                style={{ color: "#fff", marginLeft: "auto" }}
              >
                {t("btnSuivant")} {"  "}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{
                    cursor: "pointer",
                    fontSize: "16px",
                    marginRight: "auto",
                  }}
                />
              </button>
            )}
          </div>
          {popupVisible && (
            <InstancePopup instance={selectedInstance} onClose={closePopup} />
          )}
        </div>
      )}
    </>
  );
};

export default InstancesTableau;
