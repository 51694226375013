/** @format */
import apiConfig from "../config/_config";// api.js


export default async function getDocs(client,   token) {
  const apiUrl =`${apiConfig}/admin/document/list`;
  const instance = localStorage.getItem("selectedInstance")
  let bodyData = JSON.stringify({
    "InstanceId" : instance,
   "ClientId" : client 
})
  try {
    console.log(bodyData
      )
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: token,
      },
      body: bodyData
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log(data)
    return data.message.list;
  } catch (error) {

    throw new Error(error);
  }
}
