/** @format */

import { Routes, Route } from "react-router-dom";
import DashBoard from "../Component/Dashboard";
import Stats from "../Component/Dashboard/Stats/Stats";
import NavBar from "../Component/Dashboard/NavBar/NavBar";
import Import from "../Component/Dashboard/Import/Import";
import process from "process";
import Cookies from "js-cookie";
import { useEffect } from "react";
let userRole = localStorage.getItem("role");
const shouldRenderImportRoute = process.env.REACT_APP_IMPORT_APP === "true" || userRole === process.env.REACT_APP_ROLE_ADMIN;
const shouldRenderDashRoute = process.env.REACT_APP_DASHBOARD_APP === "true" || userRole === process.env.REACT_APP_ROLE_ADMIN;
const shouldRenderStatsRoute = process.env.REACT_APP_STATS_APP === "true" || userRole === process.env.REACT_APP_ROLE_ADMIN;


export default function RouterUser() {
  const checkTokenExpiration = () => {
    const token = Cookies.get('Token');
  
    if (token) {
      const tokenExpirationTime = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
      const currentTime = new Date();
      
      console.log("Token expiration time:", tokenExpirationTime);
      console.log("Current time:", currentTime);
  
      if (currentTime > tokenExpirationTime) {
        console.log("Token expired. Logging out...");
        Cookies.remove("Token");
        localStorage.clear();
        window.location.href = '/'; // Rediriger vers la page de déconnexion
      }
    }
  }; 
  
  useEffect(() => {
    // Vérifier périodiquement si le token a expiré (par exemple, toutes les minutes)
    const interval = setInterval(checkTokenExpiration, 60000); // Vérifier toutes les 60 secondes (60000 ms)
  
    return () => {
      clearInterval(interval); // Nettoyer l'intervalle à la suppression du composant
    };
  }, []);
  return (

    <>
      <NavBar />
      <Routes>
      {shouldRenderDashRoute ? (
          <Route
            path='/*'
            element={
              <>
                <DashBoard />
              </>
            }
          />
        ) : (
          <Route
            path='/*'
            element={
              <>
                <p>DashBoard en maintenance</p>
              </>
            }
          />
        )}
      { shouldRenderStatsRoute && (
        <Route
          path='/Stats'
          element={
            <>
              <Stats />
            </>
          }
        />)}
        {shouldRenderImportRoute && (
          <Route path='/import' element={<Import />} />
        )}{" "}
      </Routes>
    </>
  );
}
