import "./App.css";
import { Routes, Route } from "react-router-dom";
import RouterDefault from "./Routes/RouterDefault";
import RouterAdmin from "./Routes/RouterAdmin";
import RouterUser from "./Routes/RouterUser";
import UserAuthGuard from "./Guard/UserAuthGuard";
import process from "process";

function App() {
  if (process.env.REACT_APP_GENERAL_APP === "true" ){
  return (
      <div className='App'>
        <Routes>
          <Route path='/*' element={<RouterDefault />} />
          <Route
            path='/User/*'
            element={
               <UserAuthGuard>
                <RouterUser />
               </UserAuthGuard>
            }
          />
          <Route path='/Admin/*' element={<RouterAdmin />} />
        </Routes>
      </div>
    );
  } else {
    return (
      <div className='App'>
        <h1>Site non disponible</h1>
      </div>
    );
  }
}


export default App;
