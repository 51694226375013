import apiConfig from "../config/_config";
import Cookies from 'js-cookie';
export async function loginAdmin(username, password, MFA) {
    const loginApiUrl = `${apiConfig}/admin/user/login`;
    const loginData = {
        username: username,
        password: password,
        mfa : MFA
    };

    try {
        const loginResponse = await fetch(loginApiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(loginData),
        });

        if (!loginResponse.ok) {
            throw new Error('Login request failed with status ' + loginResponse.status);
        }
        else{
        const loginResult = await loginResponse.json();
        console.log(loginResult)
        Cookies.set('Token',loginResult.message.idToken, {
            secure: true});
            return {'idToken' : loginResult.message.idToken};
        }
        
        }
    catch (error) {
            throw new Error('An error occurred during login.');
        }
                
    }
