import apiConfig from "../config/_config"; // api.js

export async function getInstance(client = "", model = "", token) {
  const apiUrl = `${apiConfig}/admin/process/list`;
  let bodyData = {};

  // Assurez-vous que client et model ne sont pas undefined ou 'none' avant de les ajouter à bodyData
  if (model && model !== 'none') {
    bodyData.ModelId = model;
  }
  if (client && client !== 'none') {
    bodyData.ClientId = client;
  }

  try {
    console.log(bodyData);
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(bodyData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    throw new Error(error);
  }
}
