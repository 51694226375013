import { React, useState, useEffect } from "react";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Collapse,
  NavbarToggler,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import logo_ah from "../../../IMG/media/LOGOTYPES/MyActivHub/Web/PNG/logoType_light.png";
import IRM from "../../../IMG/IMF.png";
import { useNavigate } from "react-router-dom";
import fr from "../../../IMG/fr-FR.png";
import en from "../../../IMG/en-EN.png";
import { useTranslation } from "react-i18next";
import { setLanguage, getLanguage } from "../../../Language/I18n";
import process from "process";
import Cookies from "js-cookie";
export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const userRole = localStorage.getItem("role");
  const shouldActivateProfile = process.env.REACT_APP_PROFILE_APP === "true" || userRole === process.env.REACT_APP_ROLE_ADMIN;
  const navigate = useNavigate(); 
  const [langue, setLangue] = useState("en");
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.clear();
    Cookies.remove("Token");
    navigate("/");
  };

  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    setLangue(lng);
    setLanguage(lng);
  };

  useEffect(() => {
    const storedLanguage = getLanguage();
    if (storedLanguage) {
      setLangue(storedLanguage);
    } else {
      setLanguage("en");
    }
  }, []);
  const Nom = Cookies.get("Username").toLowerCase();
console.log(Nom)
  return (
    <Navbar
      dark
      expand='md'
      className='custom-navbar'
      style={{ backgroundColor: "#235073", marginBottom: 0 }}
    > <NavbarBrand href='/'>
    {Nom.includes('iron mountain') ? (
      <img
        src={IRM}
        alt='Logo IRM'
        className='logo'
        style={{ height: "30px", width: "auto", paddingLeft: "50px" }}
      />
    ) : (
      <img
        src={logo_ah}
        alt='Logo par défaut'
        className='logo'
        style={{ height: "30px", width: "auto", paddingLeft: "50px" }}
      />
    )}
  </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className='mx-auto' navbar>
          <NavItem>
            <NavLink className='text-white' href='/User/'>
              {t("acceuil")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className='text-white' href='/User/Import'>
              {t("import")}
            </NavLink>
          </NavItem>
        </Nav>
        <Nav className='ml-auto' navbar>
          <NavItem>
            <UncontrolledDropdown setActiveFromChild>
              <DropdownToggle tag='a' className='nav-link' caret>
                <img
                  src={langue === "en" ? en : fr} 
                  alt='Drapeau'
                  style={{ height: "30px", width: "auto", paddingLeft: "50px" }}
                />
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem onClick={() => changeLanguage("fr")}>
                  <img
                    src={fr}
                    alt='Drapeau'
                    style={{ height: "30px", width: "auto" }}
                  />
                {t('fr')}
                </DropdownItem>
                <DropdownItem onClick={() => changeLanguage("en")}>
                  <img
                    src={en}
                    alt='Drapeau'
                    style={{ height: "30px", width: "auto" }}
                  />
                {t('en')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>{" "}
          </NavItem>
          <NavItem>
            <UncontrolledDropdown setActiveFromChild>
              <DropdownToggle tag='a' className='nav-link' caret>
                <FontAwesomeIcon
                  icon={faUserCircle}
                  style={{ fontSize: "24px", marginRight: "8px" }}
                />
                {Nom}
              </DropdownToggle>
              <DropdownMenu end>
               { shouldActivateProfile &&(<DropdownItem >Profile</DropdownItem>)}
                <DropdownItem onClick={handleLogout}>{t("logout")}</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
}
