/** @format */

import apiConfig from "../config/_config"; // api.js

export async function updateStatusDocuments(
  currentstatus,
  newstatus,
  docList,
  token
) {
  const apiUrl = `${apiConfig}/admin/document/massupdate`;
  const doclist = JSON.parse(docList);
  var fetchbody = JSON.stringify({
    "CurrentStatus": currentstatus,
    "NewStatus": newstatus,
    "DocList" : doclist
  });
  try {
    const response = await fetch(apiUrl, {
      method: "PATCH",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: fetchbody
    });
    const data = await response.json();
    if (!response.ok) {
      console.error('Error response from server:', data); // Imprimez la réponse pour le débogage
      throw new Error('Erreur lors de la mise à jour des documents'); // Lancez l'erreur sans l'objet data
    }
    

    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching documents:", error);
    throw error;
  }
}
