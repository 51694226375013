import React, { useEffect, useState } from "react";
import fetchModel from "../../../../API/ModelADM";
import fetchClient from "../../../../API/ClientList";
import { useNavigate } from "react-router-dom";

const ModelSection = ({ token }) => {
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('none'); // Initialiser avec 'none' pour représenter l'option "Aucun"
  const navigate = useNavigate();

  const fetchModelsForClient = async (modelId) => {
    try {
      const clientsData = await fetchClient(token, modelId);
      setClients(clientsData);
    } catch (error) {
      console.error("Error fetching clients for model:", error);
    }
  };

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const modelsData = await fetchModel(null, token);
        setModels(modelsData);
        if (modelsData.length > 0) {
          setSelectedModel(modelsData[0].ModelId);
          fetchModelsForClient(modelsData[0].ModelId);
        }
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    };

    fetchModels();
  }, [token]);

  const handleRedirect = () => {
    if (selectedModel && selectedClient) {
      // Ne stocker selectedClient dans localStorage que s'il n'est pas 'none'
      if (selectedClient !== 'none') {
        localStorage.setItem('selectedClient', selectedClient);
      } else {
        localStorage.removeItem('selectedClient'); // Supprimer la clé si l'option "Aucun" est sélectionnée
      }
      localStorage.setItem('selectedModel', selectedModel);
      navigate('/Admin/Dashboard');
    } else {
      console.warn('Veuillez sélectionner un modèle avant de rediriger.');
    }
  };

  return (
    <div>
      <div className="form-group">
        <label htmlFor="selectModel">Select a model:</label>
        <select
          className="form-control"
          id="selectModel"
          onChange={(e) => {
            const selectedValue = e.target.value || null;
            setSelectedModel(selectedValue);
            fetchModelsForClient(selectedValue);
          }}
        >
          <option value="">Choose a model</option>
          {models.map((model) => (
            <option key={model.ModelId} value={model.ModelId}>
              {model.ModelName}
            </option>
          ))}
        </select>
      </div>

      {selectedModel && (
        <div className="form-group">
          <label htmlFor="selectClient">Choose a client (optional):</label>
          <select
            className="form-control"
            id="selectClient"
            onChange={(e) => setSelectedClient(e.target.value)}
          >
            <option value="none">Choisissez un client</option> {/* Ajout de l'option "None" */}
            <option value="none"> Aucun</option> {/* Ajout de l'option "Aucun" */}
            {clients.map((client) => (
              <option key={client.ClientId} value={client.ClientId}>
                {client.ClientId}
              </option>
            ))}
          </select>
        </div>
      )}
      <button type="button" className="btn btn-primary" onClick={handleRedirect}>
        Rediriger
      </button>
    </div>
  );
};

export default ModelSection;
