import React from 'react'
import NavBar from './NavBar/NavBar'
export default function AdminDashboard() {
  return (
    <>
    <NavBar/>
    <div>index</div>
    </>
  )
}
