import apiConfig from "../config/_config";// api.js


export async function getJdata( instanceId, docId, token) {

    const apiUrl =`${apiConfig}/document/query`;
    try {
        const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
            Authorization: token
            ,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ DocumentId: docId, InstanceId: instanceId }),
        });
    
        if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const data = await response.json();
        console.log(data.message.Data);
        return data.message.Data;
    } catch (error) {
        console.error("Error fetching documents:", error);
        throw error;
    }
}