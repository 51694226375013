import apiConfig from "../config/_config";

export default async function getDocsStatus(client, token, status, modelId) {
  const apiUrl = `${apiConfig}/admin/document/list/bystatus`;

  const params = {
    "Status": status,
    "ModelId": modelId,
    "ClientId": client 
  };
console.log(params)
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": token
      },
      body: JSON.stringify(params)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data);
    return data; // Vous pouvez retourner les données brutes ou les traiter selon vos besoins
  } catch (error) {
    throw new Error(error);
  }
}
