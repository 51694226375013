import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
export default function AuthGuard({ children }) {
  let token = Cookies.get('Token');
  if(!token || token === undefined || token === null) {
    return <Navigate to="/login" />;
  }


  return children;
}
