import { createSlice } from '@reduxjs/toolkit';
const cartSlice = createSlice({
  name: 'store',
  initialState: {
    store: []
  },
  reducers: {
    addToStore: (state, action) => {
      const itemInStore = state.store.find((item) => item.id === action.payload.id);
      if (itemInStore) {
        itemInStore.quantity++;
      } else {
        state.store.push({ ...action.payload, quantity: 1 });
      }
    },
    incrementQuantity: (state, action) => {
      const item = state.store.find((item) => item.id === action.payload);
      item.quantity++;
    },
    decrementQuantity: (state, action) => {
      const item = state.store.find((item) => item.id === action.payload);
      if (item.quantity === 1) {
        item.quantity = 1
      }else {
      item.quantity--;
      }
    },
    removeItem: (state, action) => {
      const removeItem = state.store.filter((item) => item.id !== action.payload);
      state.store = removeItem;
    },
    viderStore: (state, action) => {
      state.store = [];
    }
  },
});

export const cartReducer = cartSlice.reducer;
export const {
  addToStore,
  incrementQuantity,
  decrementQuantity,
  removeItem,
  viderStore
} = cartSlice.actions;