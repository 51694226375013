import { useCallback, useEffect, useState } from 'react';
import { getInstance } from '../../../API/Instance';
import { getModelList } from '../../../API/ModelList';
import PieChart from './StatsStatusInstances';
import StatsDocs from './StatsDocs';
import Cookies from 'js-cookie';
export default function Stats() {
  const [instances, setInstances] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [selectedModel, setSelectedModel] = useState('Tous'); // Définir "Tous" par défaut
  const [searchId, setSearchId] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const idToken = Cookies.get('Token');

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getInstance(idToken);
        const instances = response.message.list.map((instance) => ({
          ...instance
        }));

        setInstances(instances);
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la récupération des données de l'API",
          error
        );
      }
    }

    fetchData();
    if (idToken) {
      getModelList(idToken)
        .then((data) => {
          setModelList(data.message.list);
        })
        .catch((error) => {
          console.error("Error fetching model list:", error);
        });
    }
  }, [idToken]);

  const handleSearch = useCallback(() => {
    if (searchId) {
      const searchResults = instances.filter(instance => instance.InstanceId === searchId);

      if (searchResults.length > 0) {
        setSearchResults(searchResults);
      } else {
      }
    } else {
    }
  }, [instances, searchId]);

useEffect(() => {
  handleSearch();
}, [searchId, handleSearch]);

  return (
    <>
      <div>
        <h2>Stats status par instance</h2>
        <label htmlFor="modelSelect">Sélectionnez un modèle:</label>
        <select
          id="modelSelect"
          value={selectedModel}
          onChange={(e) => setSelectedModel(e.target.value)}
        >
          <option value="Tous">Tous</option>
          {modelList.map((model) => (
            <option value={model.ModelId}>
              {model.ModelName}
            </option>
          ))}
        </select>
        <PieChart instances={selectedModel === 'Tous' ? instances : instances.filter((instance) => instance.ModelId === selectedModel)} />
      </div>
      <br />
      <input
        type="text"
        value={searchId}
        onChange={(e) => setSearchId(e.target.value)} // Mettez à jour l'état de la recherche
      />
      <button onClick={handleSearch}>Rechercher</button>
      <br />

      {/* Affichez les résultats de la recherche s'il y en a */
      searchResults.length > 0 && (
        <StatsDocs instanceId={searchResults[0].InstanceId} />
      )}
    </>
  );
}
